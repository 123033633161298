@tailwind base;
@tailwind components;
@tailwind utilities;

.draggable {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
}

.draggable-category {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem;
}

h2 {
    margin: 0;
}

.drag-handle {
    margin-top: inherit;
    border: 1px solid rgb(209, 209, 209);
    background: rgb(209, 209, 209);
    padding: 0.1rem;
    border-radius: 4px;
}

.category-container {
    width: 90%;
}

.item {
    border: 2px solid gray;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    background: lightgray;
}


/* Hide horizontal scrollbar */


/* Show vertical scrollbar */

body {
    overflow-x: hidden;
}

.your-custom-class h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
}

.your-custom-class h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
}

.your-custom-class h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
}

.your-custom-class h4 {
    font-size: 1.17rem;
    font-weight: bold;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
}

.your-custom-class h5 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
}

.your-custom-class h6 {
    font-size: 0.83rem;
    font-weight: bold;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
}

.your-custom-class h1,
.your-custom-class h2,
.your-custom-class h3,
.your-custom-class h4,
.your-custom-class h5,
.your-custom-class h6 {
    color: inherit;
}

.your-custom-class a {
    color: #007bff;
    text-decoration: underline;
}

.your-custom-class ol,
.your-custom-class ul {
    margin-left: 1.5rem;
}

.your-custom-class li {
    margin-bottom: 0.5rem;
}


/* Styles for list items within ordered lists */

.your-custom-class ol li {
    /* Add your styles for ol li here */
    list-style-type: decimal;
    margin-left: 1rem;
}


/* Styles for list items within unordered lists */

.your-custom-class ul li {
    /* Add your styles for ul li here */
    list-style-type: disc;
    margin-left: 1rem;
}